
import { defineComponent } from 'vue';
import {
  Actions as DrawActions,
  Getters as DrawGetters,
} from '@/store/enums/DrawEnums';
import { mapActions, mapGetters } from 'vuex';

import { acronym, capitalize } from '@/utils/text';
import useBreakpoints from 'vue-next-breakpoints';
import Card from '@/views/new-design/components/Card.vue';
import { Draw } from '@/models/DrawModel';
import { delay } from 'lodash';

const drawInit = {} as unknown as Draw;

export default defineComponent({
  components: {
    Card,
  },
  data: () => ({
    loading: false,
    counterTimer: '',
    startDraw: false,
    showWinner: false,
    drawData: drawInit,
    drawLotData: {} as any,
  }),
  computed: {
    ...mapGetters({
      draw: DrawGetters.GET_DRAW,
      errors: DrawGetters.GET_ERRORS,
    }),
    breakpoint() {
      return useBreakpoints({
        mobile: 800,
        desktop: [801],
      });
    },
    isMobile() {
      return (this.breakpoint as any)?.mobile?.matches;
    },

    breadcrumbs() {
      return [
        {
          to: '/draws',
          text: 'Draws',
          current: false,
        },
        {
          to: `/draws/${this.$route.params.agent_id}`,
          text: 'Draw Details',
          current: true,
        },
      ];
    },
    drawWinner() {
      return `${this.drawLotData?.first_name} ${this.drawLotData?.last_name}`;
    },
  },
  methods: {
    ...mapActions({
      fetchDraw: DrawActions.FETCH_DRAW,
      clearDraw: DrawActions.CLEAR_DRAW,
      initializeDrawLots: DrawActions.INITIALIZE_DRAW_LOTS,
    }),

    async getDraw() {
      this.loading = true;

      await this.fetchDraw({ id: this.$route.params.draw_id }).then(() => {
        this.loading = false;
      });
    },

    onPageChanged() {
      console.log('test');
    },

    getAcronym(name = '') {
      return acronym(name);
    },
    startDrawLots() {
      this.startDraw = true;

      const payload = {
        id: this.drawData.id,
      };

      return this.initializeDrawLots(payload).then(async (data) => {
        if (data) {
          this.drawLotData = data.data;

          console.log(this.drawLotData);

          let timer = 5;

          const handle = setInterval(() => {
            console.log('timer', timer);
            this.counterTimer = timer.toString();
            timer--;
            if (timer == 0) {
              this.startDraw = false;
              this.showWinner = true;
              clearInterval(handle);
            }
          }, 1000);
        }
      });
    },
    returnToDraw() {
      this.startDraw = false;
    },
    returnToDrawLists() {
      return this.$router.replace({
        name: 'draw-lists',
      });
    },
  },
  async mounted() {
    await this.getDraw();
  },
  umounted() {
    this.clearDraw();
  },
  watch: {
    draw(values) {
      this.drawData = values as Draw;
    },
  },
});
